import { PropType } from "vue";
import { FileUploadProblems } from "~/types/types"

export const useEmits = () => ['update:modelValue', 'change']
export const useUploaderProps = {
    modelValue: {
        type: Array as PropType<File[]>
    },
    required: {
        type: Boolean,
        default: false,
    },
    showUploadProblems: {
        type: Boolean,
        default: false,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    appendFiles: {
        type: Boolean,
        default: false,
    },
    size: {
        type: Number,
        default: 3145728
    },
    maxFiles: {
        type: Number,
        default: 0
    },
    accept: {
        type: Array as PropType<string[]>,
        default: () => []
    }
}

export const useRefs = () => {
    let tempFiles = ref<any>([])
    const isMounted = ref(false)
    const fileUploader = ref()

    // проблемы/ошибки незагруженныx файлов для уведомления пользователя 
    const uploadProblems = ref({} as FileUploadProblems)

    return {
        /** refs */
        tempFiles,
        isMounted,
        fileUploader,
        uploadProblems
    }
}









